.panel{
    padding: 1.5rem 2rem 1rem 2rem; 
    background-color: #292525;   
    color: #eee;
}

section > .panel {
    padding-top: 1rem;
}

.projects {
    display: flex;
    justify-content: space-around;
    gap: 2.5rem;
    flex-wrap: wrap;
}

.panel h2{
    width: 100%;
    text-align: center;
    font-size: 2.4rem;
    font-weight: 400;
    letter-spacing: 1px;
    margin-bottom: 2rem;
}

@media screen and (max-width: 850px) {
    .panel {
        padding: 1rem 2rem 0rem 2rem; 
    }
    .projects {
        gap: 2rem;
    }
    .panel h2 {
        font-size: 1.8rem;
        margin-bottom: 1rem;
    }
}

@media screen and (max-width: 550px) {
    .panel{
        padding: 0rem 1rem 0rem 1rem;    
        flex-direction: column;
        flex-wrap: nowrap;
    }
    .projects{
        gap: 1.5rem;
    }
    .imgContainer {
        max-width: 100%;
    }
    .panel h2{
        font-size: 1.6rem;
        margin-bottom: 1rem;
    }
}

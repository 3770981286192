nav{
    width: 100%;
    max-width: 30rem;
}
.navbar ul {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    align-content: center;
    list-style: none;

}
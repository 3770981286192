
.SVGLink {
    color: rgb(224, 207, 224);
    text-decoration: none;
    text-transform: uppercase;
    padding-left: 0;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 10;
    transition: all .2s ease;
}

.SVGLink:hover,
.SVGLink:active {
    transform: scale(1.4);
    color: #a308a3;
}

.Link{
    display: inline-block;
    font-size: 1rem;
    color: #a308a3;
    cursor: pointer;
    border: 1px solid #a308a3;
    padding: .4rem .6rem;
    margin: .8rem;
    margin-bottom: .2rem;
    text-decoration: none;
    transition: all .2s;
}

.Link:hover{
    background-color: #a308a3;
    color: white;
    box-shadow: 0 2px 2px black;
}

svg{
    margin-right: 0!important;
}
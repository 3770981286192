header{
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    flex-direction: column;
    background-color: rgb(76, 119, 199);
    gap: 1.8rem;
}

header h1,  header h2 {
    text-align: center;
    font-family: 'Roboto', sans-serif;
    color: rgb(240, 240, 240);
    font-weight: 300;
}

header h1 {
    font-size: 3rem;
    text-shadow: 0px 0px 3px black;
}

header h2 {
    font-size: 1.8rem;
    text-shadow: 0px 0px 3px black;
}

header span::after {
    content: "";
    display: block;
    width: 100%;
    background-color: rgb(110, 27, 121);
    width: 1.5rem;
    height: .1rem;
}

@media screen and (max-width: 900px) {
    header {
        gap: 2rem;
    }
    header h1 {
        font-size: 2.2rem;
        letter-spacing: normal;
    }
    header h2 {
        font-size: 1.6rem;
    }
}

@media screen and (max-width: 550px) {
    header h1 {
        font-size: 2.2rem;
    }
    header h2 {
        font-size: 1.3rem;
    }
    header span::after {
        content: "";
        display: block;
        width: 100%;
        background-color: #a308a3;
        width: 1.5rem;
        height: .1rem;
    }
}
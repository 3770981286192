.Project, .ProjectNotInView{
    min-height: 40rem;
    flex: 1;
    background-color: rgb(223, 223, 223);
    box-shadow: 1px 5px 15px black;
    display: flex;
    flex-direction: column;
    position: relative;
    padding-bottom: 2.5rem;
    max-width: 30rem;
}

.Project img {
    width: 100%;
    height: auto;
    border-bottom: 2px dashed #a308a3;;
}

.Project h3 {
    clip-path: polygon(0 0, 100% 0, 100% 75%, 0 100%);
    background-color: #a308a3;
    font-size: 1.3rem;
    padding: .3rem .8rem 1rem .8rem;
    font-weight: 400;
    color: white;
    letter-spacing: 1px;
    flex-basis: 10%;
    align-self: flex-start;
    position: absolute;
    top: 0;
    left: -1rem;
    z-index: 1;
}

.small {
    min-height: 22rem;
    align-self: flex-start;
    width: 25rem;
}

.small > h3 {
    font-size: 1.1rem;
}

.Project p{
    font-size: 1rem;
    padding: 1rem;
    line-height: 1.5rem;
    font-weight: 300;
    color: black;
}

.tags {
    display: flex;
    list-style-type: none;
    flex-wrap: wrap;
    color: black;
}

.tags li {
    background-color: rgb(187, 187, 187);
    border-radius: 15px;
    padding: .3rem .7rem;
    font-size: .8rem;
    margin: .4rem .8rem;
}

.inView {
    transform: scale(1);
    opacity: 1;
    transition: transform .8s ease-out, opacity .8s ease-out;
}

.notInView {
    opacity: 0.5;
    transform: scale(.9);
    transition: transform .8s ease-out, opacity .8s ease-out;
}

@media screen and (max-width: 1000px) {
    .Project {
        flex: 75%;
        min-height: 32rem;
        max-width: 38rem;
    }
    .small {
        flex: 45%;
        min-height: 25rem;
    }
}

@media screen and (max-width: 550px) {
    .Project {
        flex: 100%;
        min-height: 35rem;
    }
    .small {
        min-height: 22rem;
    }
    .Project h3 {
        font-size: 1.2rem;
    }
    .Project p {
        font-size: 1rem;
    }
}
footer {
    background-color: #181818;
    margin-top: 3rem;
    height: 3rem;
    padding: 0 2.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 2px 4px rgb(194, 189, 189);
}

footer a, footer p {
    text-decoration: none;
    font-size: 1rem;
    color: rgb(247, 247, 247);
    font-family: 'Roboto', sans-serif;
    letter-spacing: 1px;
}

footer a:hover {
    text-decoration: underline;
    color: #A308A3;
}